// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-team-js": () => import("./../../../src/pages/company/team.js" /* webpackChunkName: "component---src-pages-company-team-js" */),
  "component---src-pages-company-why-grootan-js": () => import("./../../../src/pages/company/why-grootan.js" /* webpackChunkName: "component---src-pages-company-why-grootan-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-contactus-thank-you-js": () => import("./../../../src/pages/contactus-thank-you.js" /* webpackChunkName: "component---src-pages-contactus-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-financial-services-js": () => import("./../../../src/pages/industries/financial-services.js" /* webpackChunkName: "component---src-pages-industries-financial-services-js" */),
  "component---src-pages-industries-gaming-js": () => import("./../../../src/pages/industries/gaming.js" /* webpackChunkName: "component---src-pages-industries-gaming-js" */),
  "component---src-pages-industries-health-care-js": () => import("./../../../src/pages/industries/health-care.js" /* webpackChunkName: "component---src-pages-industries-health-care-js" */),
  "component---src-pages-industries-insurance-js": () => import("./../../../src/pages/industries/insurance.js" /* webpackChunkName: "component---src-pages-industries-insurance-js" */),
  "component---src-pages-industries-retail-js": () => import("./../../../src/pages/industries/retail.js" /* webpackChunkName: "component---src-pages-industries-retail-js" */),
  "component---src-pages-opensource-js": () => import("./../../../src/pages/opensource.js" /* webpackChunkName: "component---src-pages-opensource-js" */),
  "component---src-pages-resources-opensource-js": () => import("./../../../src/pages/resources/opensource.js" /* webpackChunkName: "component---src-pages-resources-opensource-js" */),
  "component---src-pages-solutions-ai-and-ml-js": () => import("./../../../src/pages/solutions/ai-and-ml.js" /* webpackChunkName: "component---src-pages-solutions-ai-and-ml-js" */),
  "component---src-pages-solutions-app-development-js": () => import("./../../../src/pages/solutions/app-development.js" /* webpackChunkName: "component---src-pages-solutions-app-development-js" */),
  "component---src-pages-solutions-bots-js": () => import("./../../../src/pages/solutions/bots.js" /* webpackChunkName: "component---src-pages-solutions-bots-js" */),
  "component---src-pages-solutions-cloud-solutions-js": () => import("./../../../src/pages/solutions/cloud-solutions.js" /* webpackChunkName: "component---src-pages-solutions-cloud-solutions-js" */),
  "component---src-pages-solutions-data-and-analytics-js": () => import("./../../../src/pages/solutions/data-and-analytics.js" /* webpackChunkName: "component---src-pages-solutions-data-and-analytics-js" */),
  "component---src-pages-solutions-oauth-and-openid-js": () => import("./../../../src/pages/solutions/oauth-and-openid.js" /* webpackChunkName: "component---src-pages-solutions-oauth-and-openid-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blogs-by-category-js": () => import("./../../../src/templates/blogs-by-category.js" /* webpackChunkName: "component---src-templates-blogs-by-category-js" */),
  "component---src-templates-blogs-general-js": () => import("./../../../src/templates/blogs-general.js" /* webpackChunkName: "component---src-templates-blogs-general-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/career-template.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-casestudies-home-js": () => import("./../../../src/templates/casestudies-home.js" /* webpackChunkName: "component---src-templates-casestudies-home-js" */),
  "component---src-templates-casestudies-page-js": () => import("./../../../src/templates/casestudies-page.js" /* webpackChunkName: "component---src-templates-casestudies-page-js" */)
}

